@import '../../assets/styles/styles';

.banner-home {
    background: url('../../assets/images/bg_home.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    color: $blueDark;
    width: 100%;
    text-align: center;
    padding: 7rem 2rem;
    max-height: 100%;
    .banner-home__title {
        @include font-size(48);
        font-weight: 500;
        line-height: 2;
        padding: 2rem 1rem 1rem
    }
    .banner-home__subtitle {
        font-weight: 300;
        @include font-size(18);
        padding: .5rem 30% 4rem
    }

    .btn-started {
        background-color: $blueDark;
        color: $white;
        font-weight: 300;
        padding: .75rem 2rem;
        @include font-size(14);
    }

    @media screen and (max-width: 768px) {
        padding: 2rem 1rem;
        .banner-home__title {
            @include font-size(40);
            line-height: 1;
        }
        .banner-home__subtitle {
            @include font-size(16);
            padding: .5rem 10% 4rem
        }
    }
}
