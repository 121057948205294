.activate {
  left: 300px;
  position: absolute;
  width: calc(100% - 300px);
}
.dlabnav {
  width: 20.5rem;
  padding-bottom: 0;
  height: calc(100% - 7.5rem);
  position: absolute;
  top: 7.5rem;
  padding-top: 0;
  z-index: 6;
  transition: all .2s ease;
  box-shadow: 0rem 0.9375rem 1.875rem 0rem rgba(0, 0, 0, 0.02); 
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    top: 5.5rem;
    height: calc(100% - 78px); 
  } 
}
@media only screen and (max-width: 74.9375rem) {
  .dlabnav {
    top: 5rem;
    height: calc(100% - 70px); 
  }
}
@media only screen and (max-width: 47.9375rem) {
  .dlabnav {
    width: 18rem;
  }
}
.dlabnav .dlabnav-scroll {
  position: relative;
  height: 100%;
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    width: 17rem; 
  } 
}
.dlabnav {
  ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .header-info2 {
    padding: 0 20px;
  }
  .header-info2 img {
    height: 3.75rem;
    width: 3.75rem;
    border-radius: 1.25rem;
    margin-right: 1.2rem;
  }
  .header-info2 span {
    font-size: 1.25rem;
    font-weight: 500;
    color: #393939; 
  }
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav {
    .header-info2 {
      span {
        font-size: 0.9rem; 
      }
      i {
        margin-left: 1rem;
        font-size: 1.2rem; 
      }
    }
  }
}
@media only screen and (max-width: 87.5rem) {
  .dlabnav .header-info2 {
    padding: 0;
  }
}
@media (max-width: 47.9375rem) {
  .brand-title {
    display: none; 
  }

  .footer {
    padding-left: 0; 
  }

  .dlabnav {
    left: 0;
    top: 5rem; 
  } 
}

.content-body .container {
  margin-top: 2.5rem;
}
.content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
  padding-top: 2.5rem;
  padding-right: 2.5rem;
  padding-left: 2.5rem; 
}
@media only screen and (max-width: 75rem) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 1.875rem;
    padding-right: 1.875rem;
    padding-left: 1.875rem;
  }
}
@media only screen and (max-width: 47.9375rem) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 1.25rem;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}
@media only screen and (max-width: 35.9375rem) {
  .content-body .container-fluid, .content-body .container-sm, .content-body .container-md, .content-body .container-lg, .content-body .container-xl, .content-body .container-xxl {
    padding-top: 0.9375rem;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.input-group-text {
  height: 38px;
  margin-top: 8px;
}