@import '../../assets/styles/styles';

.search-home {
    background: url('../../assets/images/bg_home_2.png');
    background-color: $blueDark;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    color: $white;
    width: 100%;
    text-align: center;
    padding: 7rem 2rem;
    max-height: 100%;
    .search-home__title {
        @include font-size(48);
        font-weight: 500;
        line-height: 2;
        padding: 2rem 1rem 1rem
    }
    .search-home__subtitle {
        font-weight: 300;
        @include font-size(18);
        padding: .5rem 30% 4rem
    }

    .search-container__form {
        background-color: $white;
        border-radius: 16px;
        padding: 1rem 3rem;
        display: inline-grid;
        align-self: center;
    }

    .btn-started {
        margin-top: 5px;
        background-color: $blueDark;
        color: $white;
        font-weight: 300;
        padding: .60rem 2rem;
        @include font-size(14);
    }
    @media  screen and (max-width: 768px) {
        padding: 2.5rem 1rem;
        .search-home__title {
            line-height: 1;
            @include font-size(40);
        }
        .search-home__subtitle {
            padding: .5rem 10% 4rem
        }
    }
}