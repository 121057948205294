@import '../../assets/styles/styles';

.category--content {
    padding: 2.5rem 10rem;
    text-align: center;
    line-height: 2;
    display: inline-block;
    width: 100%;

    .category--div-title {
        @include font-size(32);
        font-weight: 500;
        color: $blueMedium;
    }

    .category--card-container {
        width: 23%;
        max-height: 200px;
        color: $blueDark;
        padding: 2rem;
        float: left;
        margin: 1%;
        border: 1px solid #eeeeee;
        line-height: 3;
        .category--card-icon {
            img {
                width: 64px;
                fill: $blueMedium
            }
        }
        .category--card-text {
            padding: 1rem 0
        }
    }

    @media  screen and (max-width: 768px) {
        padding: 2.5rem 1.25rem;

        .category--div-title {
            @include font-size(24);
        }
        .category--card-container {
            width: 50%;
            margin: 0;
        }
    }
}