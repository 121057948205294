@import '../../assets/styles/styles';

.feature--content {
    background-color: $blueMedium;
    padding: 2.5rem 2rem;
    text-align: center;
    line-height: 2;
    display: inline-block;

    .feature--div-title {
        @include font-size(32);
        font-weight: 500;
        color: $white;
    }
    .feature--div-subtitle {
        @include font-size(18);
        font-weight: 100;
        color: $white;
    }
    @media screen and (max-width: 768px) {
        line-height: 1.25;
    }
}