@import '../../assets/styles/styles';

.contact--form {
    background: $blueDark;
    padding: 2.5rem 20%;
    color: $white;
    text-align: center;
    border-bottom: 1px solid $blueMedium;
    .contact--form-title {
        @include font-size(32);
        font-weight: 500;
        padding: 1rem 3rem;
    }
    .contact--form-subtitle {
        @include font-size(18);
        font-weight: 100;
        padding: 0 5rem;
    }

    .contact--form-form {
        display: block;
        padding: 2rem 0;
        input[type=text] {
            width: 100%;
            margin: 8px 0;
            height: 40px;
            padding: 10px 15px;
            &::placeholder {
                font-weight: 100;
                color: #666;
            }
        }
        .btn-send {
            background-color: $blueLight;
            padding: .5rem 3rem;
            width: 100%;
            color: $blueDark;
            font-weight: 300;
            margin: 8px 0;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 2.5rem 5%;
        .contact--form-title {
            padding: 1rem;
        }
        .contact--form-subtitle {
            padding: 0 1rem;
        }
    }
}