@import '../../assets/styles/styles';

:root{
    --colorprimario: #001233;
    --color1: #0e5da1;
    --color2: #ffa302;
    --color3: #2E52A4;
    --color4: #3C5A9E;
    --color5: #496198;
    --color6: #576992;
    --color7: #576992;
    --font-color-white: white;
    --font-color-black: #333;
    --font_size-h1: 6.6rem;
    --font_size-h2: 5.4rem;
    --font_size-h3: 4.0rem;
    --font_size-h4: 3.6rem;
    --font_size-h5: 2.4rem;
    --font_size-h6: 1.6rem;
}

.detail-profile {
    display: grid;
    grid-gap: 1rem;
}

.user {
    grid-column: 2/3;
    grid-row: 2/3;
}

.user img {
    height: 7rem;
}

.datos {
    display: grid;
    padding: 5% 0 0;
    grid-template-rows: repeat(5, 1fr) ;
    grid-column: 3/5 ;
    grid-row: 2/3;
    align-items: center;
    font-size: .9rem;
    font-weight: 500;
}

.analytics {
    display: grid;
    grid-column: 5 / 8;
    grid-row: 2 / 3;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 1rem;
}

.analytics .container {
    margin-top: 0 !important;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, .5fr);
    justify-items: center;
    align-items: center;
    background-color: $blueMedium;
    color: $white;
    border-radius: 16px;

    &.internal {
        background-color: $orange;
    }
    &.recommen {
        background-color: $orangeDark;
    }
}

.analytics .container :nth-child(1){
    @include font-size(14);
    font-weight: 400;
}

.analytics .container :nth-child(2){
    @include font-size(32);
    font-weight: bold;
}

.analytics .container :nth-child(3){
    @include font-size(14);
    justify-content: center;
    font-weight: 300;
}

.fotos{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column: 2 / 8;
    grid-row:  3 / 4;
    grid-gap: 3rem;
    justify-items: center;
    @include font-size(24);
    font-weight: 500;
    padding: 5% 0
}

.imagen {
    display: grid;
    grid-template-rows: 0.5 1fr;
    justify-items: center;
    align-items: center;
}

.fotos img {
    width: 10.0rem;
}

.Habilidades{
    display: grid;
    background-color: $blueMedium;
    grid-row: 4;
    grid-column: 3;
    padding: 10px;
    grid-gap: 1rem;
}

.Habilidades .titulo {
    grid-column: 4 / 6;
    font-weight: bold;
    color: $white;
    justify-self: center;
    align-self: center;
    @include font-size(24);
}

.Habilidades .fortalezas{
    grid-column: 2 / 8;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column: 1fr 1fr;
    justify-items: center;
    grid-gap: 1rem;
}

.fortalezas .fotaleza{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 0.3fr 1fr;
    justify-items: center;
    font-weight:400;
    color: $white;
    @include font-size(24);
    grid-gap: 1rem;
}

.fortalezas .fotaleza :nth-child(2){
    min-width: 3.0rem;
    max-width: 15rem;
    height: 15.0rem;
    background-repeat: no-repeat;
    background-position: 50%;
    border-radius: 50%;
    background-size: 100% auto;
}

.fortalezas .fotaleza :nth-child(3){
    @include font-size(18);
    padding-bottom: 2rem;
}
