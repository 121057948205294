@import '../../assets/styles/styles';

.footer {
    background: $blueDark;
    padding: 2rem 1rem;
    color: $white;
    width: 100%;
    .footer--contact-link {
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
            font-weight: 300;
            color: $blueMedium;
            .footer--contact-icon {
                margin: 0 8px;
                width: 18px;
                max-height: 18px;
            }
        }
    }
    .footer--copyright-p {
        @include font-size(16);
        font-weight: 300;
        color: $blueMedium;
        padding: 1.5rem .5rem 1rem;

        span {
            a {
                color: $blueMedium;
                font-style: italic;
                text-decoration: underline;
            }
        }
    }
    @media  screen and (max-width: 768px) {
        padding: .75rem;
        text-align: center;
    }
} 
