@import '../../assets/styles/styles';

.product-detail-text {
    padding-top: 1.75rem;
    padding-left: 1.875rem;
    padding-right: 1.875rem;
    padding-bottom: 4.375rem;
}
  
.product-detail .tab-content img {
    display: inline-block;
    width: 100%; 
}
.produtct-detail-tag {
    display: inline-block;
    a {
        font-style: 0.8125rem;
        color: #6e6e6e;
    }
}
  
.product-detail-content .item-tag {
    background: #828690;
    border-radius: 0.375rem;
    display: inline-block;
    @include font-size(13);
    margin-right: 0.25rem;
    padding: 0.125rem 0.75rem;
    color: $white
}
  
.filtaring-area h4, .filtaring-area .h4 {
    color: #1d1d1d;
    @include font-size(16);
    font-weight: 400;
    text-transform: lowercase;
}
  
.plus-minus-input {
    display: flex;
    width: 7.5rem;
    .input-icon {
        @include font-size(14);
        color: #6e6e6e;
    }
    .custom-btn {
        border-radius: 0;
        height: 2.5rem;
        padding: 0.75rem 0.5rem;
        background: $white;
        border: 0.0625rem solid #f5f5f5;
    }
}

.plus-minus-input .form-control:hover, 
.plus-minus-input .form-control:focus,
.plus-minus-input .form-control:active {
    border: 0.0625rem solid #f5f5f5;
}
  
.btn-reveal-trigger .avatar-xl {
    min-width: 1.875rem;
}
  
.share-view {
    display: inline-block;
    ul {
        li {
            display: inline-block;
        }
    }
    .share-icon {
        width: 2.5rem;
        height: 2.5rem;
        display: inline-block;
        border: 0.0625rem solid #f5f5f5;
        text-align: center;
        line-height: 2.5rem;
        font-style: 1rem;
        color: #f5f5f5;
        margin-right: 0.5rem;
    }
}

.tab-content > .tab-pane {
    display: none;
}
.tab-content > .active {
    display: block; 
}

.new-arrival-content{
    .item {
        @include font-size(13);
        color: #6e6e6e;
    }
    h4, .h4{
        @include font-size(20);
        font-weight: 600;
        margin-bottom: 0.625rem;
        a {
            color: $black;
        }
    }
    .price {
        font-weight: 600;
        color: var(--primary);
        @include font-size(22);
        margin-bottom: 0;
        float: right;
    }
    p {
        @include font-size(14);
        color: $grayLight;
        margin-bottom: 0.375rem;
        line-height: 1.5rem;
        span {
            font-weight: 400;
            margin-right: 0.125rem;
        }
    }
    .text-content {
        margin-top: 1.125rem;
    }
    &.text-center{
        .price {
            float: unset !important; 
        }
    }
    @media only screen and (max-width: 35.9375rem) {
        .price {
            float: none;
            margin-top: 0.625rem;
        }
    }      
}

.comment-review {
    margin-bottom: 0.9375rem;
    display: table;
    width: 100%;
    .review-text {
        color: $grayDark;
        @include font-size(13);
    }
    .client-review {
        color: $grayLight;
        padding-right: 1.25rem;
        text-decoration: underline !important;
        @include font-size(14);
    }
    .span {
        color: $grayLight;
        font-size: 0.875rem;
    }
}

@media only screen and (max-width: 35.9375rem) {
    .comment-review {
        margin-bottom: 0; 
    }
}
  
.star-rating {
    .product-review {
        font-style: 0.8125rem;
        color: #6e6e6e;
        font-weight: 400;
        text-decoration: underline !important;
    }
    li {
        display: inline-block;
        i {
            color: gold;
        }
    }
}

.btn-started {
    background-color: $orangeButton;
    color: $white;
    font-weight: 300;
    padding: .50rem 2rem;
    @include font-size(14);
    cursor: pointer;
}
