.register-content {
    background: #ffffff;
    box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.15);
    border-radius: 0.3125rem;
    .auth-form {
        padding: 3.125rem 3.125rem;
        img {
            height: 100px;
        }
        .btn {
            height: 3.125rem;
            font-weight: 700;
            &.btn-secondary {
                padding-top: 11px;
            }
        }
       .page-back {
            display: inline-block;
            margin-bottom: 0.9375rem; 
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        .auth-form {
            padding: 1.875rem .325rem;
            .button {
                width: 100%;
            }
        }
    }
}
[data-theme-version="dark"] .register-content {
    background: #212130;
    box-shadow: none;
}

.new-account {
    @media screen and (max-width: 768px) {
        width: 100%;
        display: inline-flex;
    }
}
