@import url('http://fonts.googleapis.com/css?family=Roboto:400,300,400italic,500,700,100');
@import url('http://fonts.googleapis.com/css?family=Open+Sans:400,800,300,600,700');

@import './_variables';
@import './_mixins';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', sans-serif;
  position: relative;
  font-weight: 400;
}

ul li {
  list-style: none;
}

a:hover {
  outline: none;
  text-decoration:none;
}

a:focus {
  outline:none;
  outline-offset: 0;
}

a {
  transition: 300ms;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif;
}

.btn {
  border-radius: 16px;
}

.form-control {
  border-radius: 12px;
}

select {
  font-weight: 100;
  color: #666;
  padding: 0 20px;
  width: 180px;
  @media  screen and (max-width: 768px) {
    width: 100%;
  }
}

input {
  width: 250px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

input,
select {
  border-radius: 12px;
  border: 1px solid $blueDark;
  margin: 8px 0;
  height: 40px;
  padding: 10px 15px;
  &::placeholder {
    font-weight: 100;
    color: #666;
  }
  &.is-danger {
    border-color: #ff0005;
  }
}

.help {
  font-size: .7rem;
  &.is-danger {
    color: #ff0005;
  }
}


.fs-12 {
  font-size: 0.75rem !important;
  line-height: 1.5;
}

.fs-13 {
  font-size: 0.8125rem !important;
  line-height: 1.5; }

.fs-14 {
  font-size: 0.875rem !important;
  line-height: 1.6; }

.fs-15 {
  font-size: 0.875rem !important;
  line-height: 1.5; }

.fs-16 {
  font-size: 1rem !important;
  line-height: 1.6; }
  @media only screen and (max-width: 35.9375rem) {
    .fs-16 {
      font-size: 0.875rem !important; } }

.fs-18 {
  font-size: 1.125rem !important;
  line-height: 1.5; }
  @media only screen and (max-width: 35.9375rem) {
    .fs-18 {
      font-size: 1rem !important; } }

.fs-20 {
  font-size: 1.25rem !important;
  line-height: 1.5; }

.fs-22 {
  font-size: 1.375rem !important;
  line-height: 1.5; }

.fs-24 {
  font-size: 1.5rem !important;
  line-height: 1.4; }

.fs-26 {
  font-size: 1.625rem !important;
  line-height: 1.4; }

.fs-28 {
  font-size: 1.75rem !important;
  line-height: 1.4; }
  @media only screen and (max-width: 35.9375rem) {
    .fs-28 {
      font-size: 1.5rem !important; } }

.fs-30 {
  font-size: 1.875rem !important;
  line-height: 1.4; }

.fs-32 {
  font-size: 2rem !important;
  line-height: 1.25; }

.fs-34 {
  font-size: 2.125rem !important;
  line-height: 1.25; }

.fs-35 {
  font-size: 2.1875rem !important;
  line-height: 1.25; }

.fs-36 {
  font-size: 2.25rem !important;
  line-height: 1.25; }

.fs-38 {
  font-size: 2.375rem !important;
  line-height: 1.25; }

.fs-46 {
  font-size: 2.875rem !important;
  line-height: 1.25; }

.fs-48 {
  font-size: 3rem !important;
  line-height: 1.25; }

.font-w100 {
  font-weight: 100; }

.font-w200 {
  font-weight: 200; }

.font-w300 {
  font-weight: 300; }

.font-w400 {
  font-weight: 400; }

.font-w500 {
  font-weight: 500; }

.font-w600 {
  font-weight: 600; }

.font-w700 {
  font-weight: 700; }

.font-w800 {
  font-weight: 800; }

.font-w900 {
  font-weight: 900; }
  
.order-0 {
    order: 0 !important; }
  
  .order-1 {
    order: 1 !important; }
  
  .order-2 {
    order: 2 !important; }
  
  .order-3 {
    order: 3 !important; }
  
  .order-4 {
    order: 4 !important; }
  
  .order-5 {
    order: 5 !important; }
  
  .order-last {
    order: 6 !important; }
  
  .m-0 {
    margin: 0 !important; }
  
  .m-1 {
    margin: 0.25rem !important; }
  
  .m-2 {
    margin: 0.5rem !important; }
  
  .m-3 {
    margin: 1rem !important; }
  
  .m-4 {
    margin: 1.5rem !important; }
  
  .m-5 {
    margin: 3rem !important; }
  
  .m-auto {
    margin: auto !important; }
  
  .mx-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  
  .mx-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important; }
  
  .mx-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important; }
  
  .mx-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important; }
  
  .mx-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important; }
  
  .mx-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important; }
  
  .mx-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  
  .my-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  
  .my-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important; }
  
  .my-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important; }
  
  .my-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; }
  
  .my-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important; }
  
  .my-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important; }
  
  .my-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  
  .mt-0 {
    margin-top: 0 !important; }
  
  .mt-1 {
    margin-top: 0.25rem !important; }
  
  .mt-2 {
    margin-top: 0.5rem !important; }
  
  .mt-3 {
    margin-top: 1rem !important; }
  
  .mt-4 {
    margin-top: 1.5rem !important; }
  
  .mt-5 {
    margin-top: 3rem !important; }
  
  .mt-auto {
    margin-top: auto !important; }
  
  .me-0 {
    margin-right: 0 !important; }
  
  .me-1 {
    margin-right: 0.25rem !important; }
  
  .me-2 {
    margin-right: 0.5rem !important; }
  
  .me-3 {
    margin-right: 1rem !important; }
  
  .me-4 {
    margin-right: 1.5rem !important; }
  
  .me-5 {
    margin-right: 3rem !important; }
  
  .me-auto {
    margin-right: auto !important; }
  
  .mb-0 {
    margin-bottom: 0 !important; }
  
  .mb-1 {
    margin-bottom: 0.25rem !important; }
  
  .mb-2 {
    margin-bottom: 0.5rem !important; }
  
  .mb-3 {
    margin-bottom: 1rem !important; }
  
  .mb-4 {
    margin-bottom: 1.5rem !important; }
  
  .mb-5 {
    margin-bottom: 3rem !important; }
  
  .mb-auto {
    margin-bottom: auto !important; }
  
  .ms-0 {
    margin-left: 0 !important; }
  
  .ms-1 {
    margin-left: 0.25rem !important; }
  
  .ms-2 {
    margin-left: 0.5rem !important; }
  
  .ms-3 {
    margin-left: 1rem !important; }
  
  .ms-4 {
    margin-left: 1.5rem !important; }
  
  .ms-5 {
    margin-left: 3rem !important; }
  
  .ms-auto {
    margin-left: auto !important; }
  
  .p-0 {
    padding: 0 !important; }
  
  .p-1 {
    padding: 0.25rem !important; }
  
  .p-2 {
    padding: 0.5rem !important; }
  
  .p-3 {
    padding: 1rem !important; }
  
  .p-4 {
    padding: 1.5rem !important; }
  
  .p-5 {
    padding: 3rem !important; }
  
  .px-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  
  .px-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important; }
  
  .px-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important; }
  
  .px-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  
  .px-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important; }
  
  .px-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important; }
  
  .py-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  
  .py-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important; }
  
  .py-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important; }
  
  .py-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important; }
  
  .py-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important; }
  
  .py-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important; }
  
  .pt-0 {
    padding-top: 0 !important; }
  
  .pt-1 {
    padding-top: 0.25rem !important; }
  
  .pt-2 {
    padding-top: 0.5rem !important; }
  
  .pt-3 {
    padding-top: 1rem !important; }
  
  .pt-4 {
    padding-top: 1.5rem !important; }
  
  .pt-5 {
    padding-top: 3rem !important; }
  
  .pe-0 {
    padding-right: 0 !important; }
  
  .pe-1 {
    padding-right: 0.25rem !important; }
  
  .pe-2 {
    padding-right: 0.5rem !important; }
  
  .pe-3 {
    padding-right: 1rem !important; }
  
  .pe-4 {
    padding-right: 1.5rem !important; }
  
  .pe-5 {
    padding-right: 3rem !important; }
  
  .pb-0 {
    padding-bottom: 0 !important; }
  
  .pb-1 {
    padding-bottom: 0.25rem !important; }
  
  .pb-2 {
    padding-bottom: 0.5rem !important; }
  
  .pb-3 {
    padding-bottom: 1rem !important; }
  
  .pb-4 {
    padding-bottom: 1.5rem !important; }
  
  .pb-5 {
    padding-bottom: 3rem !important; }
  
  .ps-0 {
    padding-left: 0 !important; }
  
  .ps-1 {
    padding-left: 0.25rem !important; }
  
  .ps-2 {
    padding-left: 0.5rem !important; }
  
  .ps-3 {
    padding-left: 1rem !important; }
  
  .ps-4 {
    padding-left: 1.5rem !important; }
  
  .ps-5 {
    padding-left: 3rem !important; }
  
  .fs-1 {
    font-size: calc(1.35rem + 1.2vw) !important; }
  
  .fs-2 {
    font-size: calc(1.3125rem + 0.75vw) !important; }
  
  .fs-3 {
    font-size: calc(1.275rem + 0.3vw) !important; }
  
  .fs-4 {
    font-size: 1.125rem !important; }
  
  .fs-5 {
    font-size: 1rem !important; }
  
  .fs-6 {
    font-size: 0.938rem !important; }
  