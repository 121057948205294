.authincation-content {
    background: #fff;
    box-shadow: 0 0 2.1875rem 0 rgba(154, 161, 171, 0.15);
    border-radius: 0.3125rem;

    .auth-form {
        padding: 3.125rem 3.125rem;
        img {
            height: 100px;
        }
        .btn {
            height: 3.125rem;
            font-weight: 700; 
        }
       .page-back {
            display: inline-block;
            margin-bottom: 0.9375rem; 
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        .auth-form {
            padding: 1.875rem 0rem; 
        }
    }
}
[data-theme-version="dark"] .authincation-content {
    background: #212130;
    box-shadow: none;
}
