@import '../../assets/styles/styles';

#form {
    margin-left: auto;
    margin-right: auto;
    width: 965px;
    height: 450px;
    padding: 30px;
    border-radius: 10px;
    background-clip: padding-box; 
    overflow: hidden;
}

.login-form {
    h2 {
        color: $grayDark;
        font-size: 20px;
        font-weight: 300;
        margin-bottom: 30px;
    }
    form {
        input {
            width: 100%;
            height: 50px;
            border: 1px solid $orange;
            border-radius: 10px;
            background-clip: padding-box; 
            display: block;
            font-size: 18px;
            color: $white;
            padding: 0 20px;
            margin-bottom: 20px;
        }
        button {
            margin-top: 25px;
            background: $orange;
            border: medium none;
            border-radius: 0;
            color: $white;
            display: block;
            padding: 6px 25px;
        }
        label {
            input {
                border: medium none;
                display: inline-block;
                height: 0;
                margin-bottom: 0;
                outline: medium none;
                padding-left: 0;
            }
        }
    }
}

@media only screen and ( min-width: 320px ) {
    #form {
        width: 100%;
        height: auto;
    }
}