@import '../../assets/styles/styles';

.navbar {
    background-color: $blueDark;
    width: 100%;
    height: 80px;
    align-items: center;
    display: grid;
    grid-template-columns: 64px auto;
    transition: all .2s ease;

    .menu-bars {
        margin-left: 1rem;
        @include font-size(16);
        background: none;
        img {
            height: 60px;
        }
    }
    ul {
        li {
            padding-right: 15px;
            padding-left: 15px;
            &:first-child {
                padding-left: 0;
            }
            .item-menu{
                color: $white;
            }
        }
    }
}
