@import '../../assets/styles/styles';

.listtasker-home {
    background-color: #f5f5f5;
    width: 100%;
    text-align: center;
    padding: 7rem 10rem;
    max-height: 100%;
    display: grid;
    grid-template-columns: 2fr 200px;
    .listtasker-description {
        text-align: left;
        border-bottom: 1px solid #e6e6e6;
        padding: 2rem 0;
        .listtasker-description__title {
            @include font-size(22);
            font-weight: 700;
            color: $blueDark;
        }
        .listtasker-description__details {
            display: flex;
            justify-content: space-between;
            @include font-size(14);
            span {
                .svg-inline--fa  {
                    color: rgb(238, 198, 18)
                }
                img {
                    height: 22px;
                }
            }
        }
    }

    .listtasker-callout__button {
        .btn-started {
            margin-top: 3rem;
            background-color: #F93A0B;
            color: $white;
            font-weight: 300;
            padding: .50rem 2rem;
            @include font-size(14);
            cursor: pointer;
        }
    }

    @media  screen and (max-width: 768px) {
        padding: 3rem 1.5rem;
        display: inherit;
        .listtasker-description {
            border-bottom: none;
            padding: 1rem 0;
            .listtasker-description__details {
                display: inline-grid;
            }
        }
        .listtasker-callout__button {
            border-bottom: 1px solid #e6e6e6;
            .btn-started {
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        }
    }
}
