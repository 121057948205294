@import '../../assets/styles/styles';

.item--content {
    display: flex;
    justify-content: left;
    align-content: center;
    width: 33.333%;
    float: left;
    padding: 2.5rem;

    .item--content-icon {
        color: $white;
    }
    .item--content-div {
        text-align: left;
        padding: 0 1rem;
        .item--content-title {
            color: $blueDark;
            @include font-size(18);
            font-weight: 500;
        }
        .item--content-description {
            @include font-size(14);
            font-weight: 300;
            color: $white;
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 1.5rem .5rem;
    }
}