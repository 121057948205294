@import '../../assets/styles/styles';

.about--content {
    background-color: $white;
    text-align: center;
    padding: 2.5rem 2rem;
    color: $blueDark;
    .about--content-title {
        @include font-size(32);
        font-weight: 500;
    }
    .about--content-div {
        display: flex;
        align-items: center;
        margin: 1.5rem 0;
        img {
            max-width: 600px;
        }
        .about--content-description {
            text-align: left;
            font-weight: 300;
            padding: 0 2rem
        }
    }

    @media screen and (max-width: 768px) {
        .about--content-div {
            display: inline;
            img {
                max-width: 250px;
            }
        }
    }
}