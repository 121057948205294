@import '../../assets/styles/styles';

.header {
    padding-bottom: 10px;
    padding-top: 10px;
}

.mainmenu {
    display: grid;
    grid-template-columns: 64px auto;
    align-items: center;
    width: 100%;
    transition: all .2s ease;
    .navbar__brand {
        padding: 7px;
        display: flex;
        align-items: center;
    }
    ul {
        li {
            padding-right: 15px;
            padding-left: 15px;
            &:first-child {
                padding-left: 0;
            }
            .link-menu {
                @include font-size(14);
                font-weight: 500;
                padding: 0 0 10px 0;
                color: $blueDark;
                cursor: pointer;
                &:hover,
                .active {
                    background: none;
                    color: $blueMedium;
                }
                &.login {
                    border: 1px solid $orangeDark;
                    color: $orangeDark;
                    padding: 10px;
                    border-radius: 8px;
                }
            }
        }
    }

    @media  screen and (max-width: 768px) {
        display: block;
        ul {
            li {
                padding-right: 5px;
                padding-left: 5px;
                .link-menu {
                    &.login {
                        padding: 5px 10px
                    }
                }
            }
        }
    }
}

.scrolled{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: 1;
}

.navbar-nav {
    flex-direction: unset;
    justify-content: flex-end;
    @media  screen and (max-width: 768px) {
        justify-content: center;
    }
}
 